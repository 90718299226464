<template>
    <div class="startBox" :class="startPc?'startPc':''">
        <div class="toLogin" @click="goLogin"></div>

    </div>
    
</template>

<script>
    export default {
        name: "Start",
        data(){
          return{
              startPc:false
          }
        },
        methods:{
            goLogin(){
                this.$router.push("/login")
            },
            //长宽判断然后显示图片
            system (){
                const width= document.body.clientWidth;
                const height=document.body.clientHeight;
                if(width>height){
                    this.startPc=true;
                }
            }
        },
        mounted() {
            this.system()
        }
    }
</script>

<style scoped lang="less">
.startBox{
    background: url("https://sxd.kk.aliyun.com/img/startBg.jpg") no-repeat center;
    background-color: #ffffff;
    background-size: 100% 100%;
    .toLogin{
        position: absolute;
        width: 40%;
        height: 7%;
        top: 47%;
        left: 30%;
    }
}
.startPc{
    background: url("https://sxd.kk.aliyun.com/img/startBg1.png") no-repeat center;
    background-color: #ffffff;
    background-size: 100% 100%;
    .toLogin{
        position: absolute;
        width: 18%;
        height: 8%;
        top: 76%;
        left: 56%;
    }
}
</style>