<template>
    <div class="gcsBox" id="gcsBox">

        <div id="gameDom" class="gameDom"></div>
        <!--    <div class="audio" id="audioBtn" @click="changeAudio">-->
        <!--      <van-image width="100%" height="100%" :src="audioSrc"/>-->
        <!--    </div>-->
<!--        <div class="online_num">当前在线人数：{{online_num}}</div>-->
        <div class="queue_size" v-if="showRank">排队位置：{{user_rank}} / {{queue_size}}</div>
<!--        <div class="avaliable_res">当前可用资源数：{{avaliable_resres}}</div>-->
        <div class="user_rank" :class="isFull?'full':'nofull'" @click="full"></div>
        <div class="tipBox">
            <div>{{tips}}</div>
        </div>
        <!--    <van-swipe class="my-swipe" :class="showSwiper?'zindexMax':''" :autoplay="300000" indicator-color="white" vertical-->
        <!--               style="height: 100%;">-->

        <!--      <van-swipe-item v-for="(item,index) in swiperList" :key="index">-->
        <!--        <van-image style="width: 100%;height: 100%;" :src="item.imgSrc" />-->
        <!--      </van-swipe-item>-->
        <!--    </van-swipe>-->
        <swiper class="my-swipe" :class="showSwiper?'zindexMax':''"></swiper>
    </div>
</template>

<script>
    import {reactive, onMounted, onUnmounted, toRefs, nextTick} from 'vue'

    import swiper from '@/components/Swiper'
    import {getData, getGcs} from '@/service/user'
    import {Toast, Dialog} from "vant";
    import {useRouter} from "vue-router";


function CheckIsAndroid() {
    var browser = {
        versions: function () {
            var u = navigator.userAgent, app = navigator.appVersion;
            return { //移动终端浏览器版本信息 
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端 
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器 //u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器 
                iPad: u.indexOf('iPad') > -1, //是否iPad 
            };
        }(),
    }
    //if (browser.versions.iPhone || browser.versions.iPad || browser.versions.ios) {
    //    return false;
    //}
    if (browser.versions.android)
        return true;
    return false;
}


    const gcs = new GCSSDK();
    export default {
        name: 'home',
        components: {
            swiper
        },
        setup() {
            const router = useRouter();
            const state = reactive({
                audioSrc: 'https://sxd.kk.aliyun.com/img/shengyinkai.png',
                isAudio: true,
                online_num: "0",
                queue_size: "0",
                avaliable_resres: "0",
                user_rank: "0",
                tips: "",
                timer1: null,
                timer2: null,
                showSwiper: true,
                isFull: false,
                showRank:true
            })

            const full = () => {
                var element = document.getElementById('gcsBox');
                const video=document.querySelector("#gameDom video");
                state.isFull=!state.isFull;
                if(state.isFull){
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.webkitRequestFullscreen) {
                        element.webkitRequestFullscreen();
                    }else if(element.oRequestFullscreen){
                        element.oRequestFullscreen();
                    } else if (element.msRequestFullscreen) {
                        element.msRequestFullscreen();
                    }else if (video.webkitRequestFullscreen) {
                        video.webkitRequestFullscreen();
                    }
                }else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if(document.oRequestFullscreen){
                        document.oCancelFullScreen();
                    }else if (document.webkitExitFullscreen){
                        document.webkitExitFullscreen();
                    }else if (video.webkitExitFullscreen){
                        video.webkitExitFullscreen();
                    }
                }

            }
            const changeAudio = () => {
                state.isAudio = !state.isAudio;
                if (state.isAudio) {
                    gcs.setVolume(1);
                    state.audioSrc = 'https://sxd.kk.aliyun.com/img/shengyinkai.png';
                } else {
                    gcs.setVolume(0);
                    state.audioSrc = 'https://sxd.kk.aliyun.com/img/shengyinjingyin.png';

                }
            }
            const getTeople = () => {
                // getData({}).then(res => {
                //     console.log(res);
                //     state.online_num = Number(res.data.sxd_online_num);
                //     state.queue_size = Number(res.data.sxd_queue_size);
                //     state.avaliable_resres = Number(res.data.sxd_avaliable_res);
                //     state.user_rank = Number(res.data.sxd_user_rank);
                //     state.timer1 = setTimeout(function () {
                //         getTeople();
                //     }, 5000)
                // })
            }
            const infoGcs = () => {
                getGcs({}).then(res => {
                    if (res.data.sxd_state) {
                        state.online_num = Number(res.data.sxd_online_num);
                        state.queue_size = Number(res.data.sxd_queue_size);
                        state.avaliable_resres = Number(res.data.sxd_avaliable_res);
                        state.user_rank = Number(res.data.sxd_user_rank);
                        const initConfig = {
                            accessKey: res.data.sxd_accessKey,
                            token: res.data.sxd_token,
                            userId: res.data.sxd_userId,
                            useLog: true,
                            sessionId: res.data.sxd_sessionId
                        }
                        const container = document.querySelector('#gameDom');
                        const prepareConfig = {
                            appId: res.data.sxd_appId,
                            appVersion: res.data.sxd_appVersion,
                            appStartParam: res.data.sxd_appStartParam,
                            container,
                            width: -1,
                            height: -1,
                        }
                        //console.log('gcs-init')
                        gcs.init(initConfig).then(ready => {
                            //console.log('gcs-preoare')
                            gcs.prepare(prepareConfig);
                        }).catch(e => {
                            // console.log("ready-false"); // false
                        });
                        // 开始监听SDK事件
                        gcs.on('GCSEvent', res => {
                            const {code, message} = res;
                            // console.log(message);
                            // console.log(code);
                            state.tips = message;
                            switch (code) {
                                case '201010':
                                    //console.log('gcs-start')
                                    gcs.start(); // 应用运行环境准备完成
                                    break;
                                case '401010': // 接收应用的数据
                                    //console.log(message);
                                    break;
                                case '902011': // 服务器连接成功
                                    state.showSwiper = false;
                                    // document.getElementById('swiper').style.display='none';
                                    break;
                                case '902012': // 播放声音画⾯准备就绪
                                    gcs.setVolume(1);//播放声音
                                    state.showRank=false;
                                    break;
                                case '501030': // 资源包CU不足
                                    // window.location.reload();

                                    clearTimeout(state.timer1);
                                    clearTimeout(state.timer2);
                                    state.timer1 = null;
                                    state.timer2 = null;
                                    Dialog.confirm({
                                        title: '温馨提示',
                                        message: '资源包CU不足，请重新登录。',
                                    }).then(() => {
                                        router.push({path: "/login"})
                                    }).catch(() => {
                                        window.location.reload();
                                    })
                                    ;
                                    break;
                                case '501041': //  启动会话请求被流控

                                    clearTimeout(state.timer1);
                                    clearTimeout(state.timer2);
                                    state.timer1 = null;
                                    state.timer2 = null;
                                    Dialog.confirm({
                                        title: '温馨提示',
                                        message: '启动会话请求被流控，请重新登录。',
                                    }).then(() => {
                                        router.push({path: "/login"})
                                    }).catch(() => {
                                        window.location.reload();
                                    });
                                    break;
                                case '903010': //  因⻓时间未操作导致踢出
                                    Dialog.alert({
                                        title: '温馨提示',
                                        message: '因⻓时间未操作导致踢出，请重新登录。',
                                    }).then(() => {
                                        router.push({path: "/login"})
                                    });
                                    break;
                                case '903020': //  因账户在其它设备登录⽽被踢出
                                    Dialog.alert({
                                        title: '温馨提示',
                                        message: '因账户在其它设备登录⽽被踢出，请重新登录。',
                                    }).then(() => {
                                        router.push({path: "/login"})
                                    });
                                    break;
                                case '904010': //  ⽹络断开，进⼊重连状态
                                    Toast('⽹络断开，进⼊重连状态');
                                    break;
                                case '904020': //  重连成功
                                    Toast('重连成功');
                                    break;
                                case '904030': //  重连失败
                                    Toast('重连失败');
                                    break;

                                default:
                                    break;
                            }
                        })
                    } else {
                        state.timer2 = setTimeout(function () {
                            infoGcs();
                        }, 5000);
                    }


                })
            }
            const senMessage = () => {
                let video_div = document.getElementById('gameDom');

                // gcs.setVolume(1);
                video_div.onmousedown = function (ev) {
                    var e = ev || window.event
                    var width = video_div.clientWidth;
                    var height = video_div.clientHeight;
                    var x = e.offsetX / video_div.clientWidth
                    var y = e.offsetY / video_div.clientHeight
                    if (width < height) {
                        var x1 = x;
                        x = Math.round(y * 65536);
                        y = Math.round((1 - x1) * 65536);
                    } else {
                        x = Math.round(x * 65536);
                        y = Math.round(y * 65536);
                    }

                    // console.log(80, 1, x%256, x>>8, y%256, y>>8, 0, 255, 1)
                    gcs.sendDataToService(new Uint8Array([80, 1, x % 256, x >> 8, y % 256, y >> 8, 0, 255, 1]))
                    video_div.onmouseup = function (ev) {
                        var e = ev || window.event;
                        var x = e.offsetX / video_div.clientWidth;
                        var y = e.offsetY / video_div.clientHeight;
                        if (width < height) {
                            var x1 = x;
                            x = Math.round(y * 65536);
                            y = Math.round((1 - x1) * 65536);
                        } else {
                            x = Math.round(x * 65536);
                            y = Math.round(y * 65536);
                        }
                        // console.log(81, 1, x%256, x>>8, y%256, y>>8, 0, 255, 1);
                        gcs.sendDataToService(new Uint8Array([81, 1, x % 256, x >> 8, y % 256, y >> 8, 0, 255, 1]));
                    }
                    video_div.onmousemove = function (ev) {
                        var e = ev || window.event;
                        var x = e.offsetX / video_div.clientWidth;
                        var y = e.offsetY / video_div.clientHeight;

                        if (width < height) {
                            var x1 = x;
                            x = Math.round(y * 65536);
                            y = Math.round((1 - x1) * 65536);
                        } else {
                            x = Math.round(x * 65536);
                            y = Math.round(y * 65536);
                        }
                        // console.log(82, 1, x%256, x>>8, y%256, y>>8, 0, 255, 1);
                        gcs.sendDataToService(new Uint8Array([82, 1, x % 256, x >> 8, y % 256, y >> 8, 0, 255, 1]));
                    }
                }
//移动端浏览器 手指点击相关事件
                var channelObjectA ="";
                var channelObjectB ="";

                video_div.ontouchstart = function (ev) {
                    //手指头触摸屏幕上的事件
                    // var touch = ev.changedTouches[0] //获取第一个触点

                    if(ev.changedTouches.length>2)
                    {
                        return;
                    }

                    var width = video_div.clientWidth;
                    var height = video_div.clientHeight;
                    var sendArr = [80, ev.changedTouches.length];
                    for (var i = 0; i < ev.changedTouches.length; i++) {
                        var x = ev.changedTouches[i].pageX / video_div.clientWidth;
                        var y = ev.changedTouches[i].pageY / video_div.clientHeight;

                        if (x < 0) {
                            x = Math.abs(x);
                        }
                        if (x > 1) {
                            x = 2 - x;
                        }
                        if (y < 0) {
                            y = Math.abs(y);
                        }
                        if (y > 1) {
                            y = 2 - y;
                        }
                        //console.log(x, y);
                        if (width < height) {
                            var x1 = x;
                            x = Math.round(y * 65536);
                            y = Math.round((1 - x1) * 65536);
                        } else {
                            x = Math.round(x * 65536);
                            y = Math.round(y * 65536);
                        }


                        if (!CheckIsAndroid()) {
                            //如果是IOS
                            if(!channelObjectA)
                            {
                                channelObjectA=ev.changedTouches[i].identifier;
                                sendArr.push(x % 256, x >> 8, y % 256, y >> 8, 0, 255, 1)
                            }

                            else if(!channelObjectB)
                            {
                                channelObjectB=ev.changedTouches[i].identifier;
                                sendArr.push(x % 256, x >> 8, y % 256, y >> 8, 1, 255, 1)
                            }
    

                            if((channelObjectA==channelObjectB)&&channelObjectB)
                            {
                                channelObjectA="";
                            }
                        }

                        if (CheckIsAndroid()) {
                            //如果是Android
                             sendArr.push(x % 256, x >> 8, y % 256, y >> 8, Math.abs(ev.changedTouches[i].identifier % 2), 255, 1);
                        } 



                    }

                    // state.tips = sendArr.toString();
                    state.tips = "";
                    // console.log(sendArr);
                    gcs.sendDataToService(new Uint8Array(sendArr));
                }

                video_div.ontouchmove = function (ev) {
                    //手指头在屏幕上滑动触发的事件
                    // var touch = ev.changedTouches[0] ;//获取第一个触点
                    // var x = touch.pageX / video_div.clientWidth;
                    // var y = touch.pageY / video_div.clientHeight;

                    if(ev.changedTouches.length>2)
                    {
                        return;
                    }

                    var width = video_div.clientWidth;
                    var height = video_div.clientHeight;
                    var sendArr1 = [82, ev.changedTouches.length];
                    for (var j = 0; j < ev.changedTouches.length; j++) {
                        var x = ev.changedTouches[j].pageX / video_div.clientWidth;
                        var y = ev.changedTouches[j].pageY / video_div.clientHeight;

                        if (x < 0) {
                            x = Math.abs(x);
                        }
                        if (x > 1) {
                            x = 2 - x;
                        }
                        if (y < 0) {
                            y = Math.abs(y);
                        }
                        if (y > 1) {
                            y = 2 - y;
                        }
                        //console.log(x, y);
                        if (width < height) {
                            var x1 = x;
                            x = Math.round(y * 65536);
                            y = Math.round((1 - x1) * 65536);
                        } else {
                            x = Math.round(x * 65536);
                            y = Math.round(y * 65536);
                        }
                        // console.log(ev.changedTouches[j].identifier)


                        if (!CheckIsAndroid()) {
                            //如果是IOS

                            if(ev.changedTouches[j].identifier==channelObjectA)
                            {
                                sendArr1.push(x % 256, x >> 8, y % 256, y >> 8, 0, 255, 1);
                            }else if(ev.changedTouches[j].identifier==channelObjectB)
                            {
                                sendArr1.push(x % 256, x >> 8, y % 256, y >> 8, 1, 255, 1);
                            }

                        }

                        if (CheckIsAndroid()) {
                            //如果是Android
                            sendArr1.push(x % 256, x >> 8, y % 256, y >> 8, Math.abs(ev.changedTouches[j].identifier % 2), 255, 1);
                        } 
                       

                    }
                    // sendArr1.push(83, 248, 50, 193, 1, 255,1);
                    // state.tips = sendArr1.toString();
                    state.tips = "";
                    // console.log(sendArr1);
                    gcs.sendDataToService(new Uint8Array(sendArr1));
                }
                video_div.ontouchend = function (ev) {
                    //当手指从屏幕上离开的时候触发
                    // var touch = ev.changedTouches[0]; //获取第一个触点
                    // console.log(ev);
                    // console.log(ev.touches);
                    // var x = touch.pageX / video_div.clientWidth;
                    // var y = touch.pageY / video_div.clientHeight;

                    if(ev.changedTouches.length>2)
                    {
                        return;
                    }

                    var width = video_div.clientWidth;
                    var height = video_div.clientHeight;
                    var sendArr2 = [81, ev.changedTouches.length];
                    for (var k = 0; k < ev.changedTouches.length; k++) {
                        var x = ev.changedTouches[k].pageX / video_div.clientWidth;
                        var y = ev.changedTouches[k].pageY / video_div.clientHeight;
                        if (x < 0) {
                            x = Math.abs(x);
                        }
                        if (x > 1) {
                            x = 2 - x;
                        }
                        if (y < 0) {
                            y = Math.abs(y);
                        }
                        if (y > 1) {
                            y = 2 - y;
                        }
                        //console.log(x, y);
                        if (width < height) {
                            var x1 = x;
                            x = Math.round(y * 65536);
                            y = Math.round((1 - x1) * 65536);
                        } else {
                            x = Math.round(x * 65536);
                            y = Math.round(y * 65536);
                        }


                        if (!CheckIsAndroid()) {
                            //如果是IOS
                            
                            if(ev.changedTouches[k].identifier==channelObjectA)
                            {
                                channelObjectA="";
                                sendArr2.push(x % 256, x >> 8, y % 256, y >> 8, 0, 255, 1);
                            }
                            else if(ev.changedTouches[k].identifier==channelObjectB)
                            {
                                channelObjectB="";
                                sendArr2.push(x % 256, x >> 8, y % 256, y >> 8, 1, 255, 1)
                            }
                            else
                            {
                                channelObjectA="";
                                channelObjectB="";
                            }
                        }


                        if (CheckIsAndroid()) {
                            //如果是Android
                            sendArr2.push(x % 256, x >> 8, y % 256, y >> 8, Math.abs(ev.changedTouches[k].identifier % 2), 255, 1);
                        } 



                        // sendArr2.push(83, 248, 50, 193, 1, 255,1);
                    }
                    // state.tips = sendArr2.toString();
                    state.tips = "";
                    // console.log(sendArr2);
                    gcs.sendDataToService(new Uint8Array(sendArr2));
                }
            }
            onMounted(async () => {
                infoGcs();
                // setInterval(function () {
                getTeople();
                // },5000)
                senMessage();
                document.body.addEventListener('touchmove', function (e) {
                e.preventDefault();
            }, {passive: false});
                // setTimeout(function () {
                //   router.push({path: "/about"})
                // },3000)
            })
            onUnmounted(() => {
                clearTimeout(state.timer1);
                clearTimeout(state.timer2);
                state.timer1 = null;
                state.timer2 = null;
                gcs.off('GCSEvent');
            })
            nextTick(() => {

            })

            return {
                ...toRefs(state),
                changeAudio,
                getTeople,
                senMessage,
                full
            }
        },
    }
</script>

<style scoped>
    .gcsBox {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .gameDom {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #323233;
        z-index: 995;
    }

    .audio {
        position: absolute;
        top: 25px;
        right: 40px;
        width: 15px;
        height: 15px;
        /*background: url("https://sxd.kk.aliyun.com/img/images/shengyinkai.png") no-repeat;*/
        /*background-size: 100%;*/
        z-index: 999;
        cursor: pointer;
        transform: rotate(90deg);
    }

    .online_num {
        position: absolute;
        top: 2rem;
        right: 10px;
        z-index: 999;
        color: #fff;
        width: 3.5rem;
        /*text-align: right;*/
        transform: rotate(90deg);
    }

    .queue_size {
        position: absolute;
        top: 2rem;
        right: 38px;
        z-index: 999;
        color: #fff;
        width: 3.5rem;
        /*text-align: right;*/
        transform: rotate(90deg);
    }

    .avaliable_res {
        position: absolute;
        top: 2rem;
        right: 60px;
        z-index: 999;
        color: #fff;
        width: 3.5rem;
        /*text-align: left;*/
        transform: rotate(90deg);
    }

    .user_rank {
        position: absolute;
        top: 1rem;
        right: 38px;
        z-index: 999;
        color: #fff;
        width: 1.5rem;
        /*text-align: left;*/
        transform: rotate(90deg);
        height: 1rem;
    }
    .full{
        background: url("https://sxd.kk.aliyun.com/img/noFull.png") no-repeat left center;
        background-size: 0.5rem 0.5rem;
    }
    .nofull{
        background: url("https://sxd.kk.aliyun.com/img/full.png") no-repeat left center;
        background-size: 0.5rem 0.5rem;
    }

    .tipBox {
        position: absolute;
        top: 0.3rem;
        left: 0;
        z-index: 999;
        color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .jump {
        position: absolute;
        width: 25px;
        height: 25px;
        bottom: 3rem;
        right: 2rem;
        border-radius: 25px;
        border: 2px solid #d7d7d7;
        /*background-color: #939393;*/
        z-index: 997;
        text-align: center;
        line-height: 35px;
        color: #ffffff;
    }

    .my-swipe {
        position: absolute;
        z-index: 900;
        height: 100%;
        width: 100%;
    }

    .zindexMax {
        z-index: 998;
    }

    .my-swipe .van-swipe-item {
        /*color: #fff;*/
        /*font-size: 20px;*/
        /*line-height: 150px;*/
        /*text-align: center;*/
        /*background-color: #39a9ed;*/
    }

    .gameDom >>> .videoBox {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-touch-callout: none;
    }

    .gameDom >>> video {
        object-fit: fill;
    }

    @media screen and (orientation: landscape) {
        /*横屏样式*/
        #audioBtn {
            transform: rotate(0deg);
            top: 10px;
            left: 20px;
        }

        .online_num {
            transform: rotate(0deg);
            top: 35px;
            left: 20px;
        }

        .queue_size {
            transform: rotate(0deg);
            top: 20%;
            left: 20px;
        }

        .avaliable_res {
            transform: rotate(0deg);
            top: 65px;
            left: 20px;
        }

        .user_rank {
            transform: rotate(0deg);
            top: 10%;
            left: 25px;
        }
    }


    @media (orientation: portrait) {
        .gameDom >>> .videoBox {
            width: 100vh !important;
            height: 100vw !important;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg);
            -webkit-touch-callout: none;

        }

        .gameDom >>> video {
            object-fit: fill;
        }

        .tipBox {
            top: 50%;
            left: 44%;
            transform: rotate(90deg);
        }
    }

</style>