

<template>
  <swiper class="swiper-container" :pagination="{ clickable: true}"
          :autoplay="{ autoplay: true }"
          loop
          direction="vertical"

  >
    <swiper-slide class="swiper-slide" >
      <div class="swiperImg swiperImg1"></div>
    </swiper-slide>
    <swiper-slide class="swiper-slide" >
      <div class="swiperImg swiperImg2"></div>
    </swiper-slide>
    <swiper-slide class="swiper-slide" >
      <div class="swiperImg swiperImg3"></div>
    </swiper-slide>
    <swiper-slide class="swiper-slide" >
      <div class="swiperImg swiperImg4"></div>
    </swiper-slide>
    <swiper-slide class="swiper-slide" >
      <div class="swiperImg swiperImg5"></div>
    </swiper-slide>
    <swiper-slide class="swiper-slide" >
      <div class="swiperImg swiperImg6"></div>
    </swiper-slide>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->
    <!-- 如果需要滚动条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </swiper>

</template>

<script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
  } from "swiper";
  import { Toast } from 'vant'
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
  import "swiper/swiper-bundle.css";
  import {onMounted, reactive, toRefs} from "vue";
  let images=[
    "https://sxd.kk.aliyun.com/img/swiper1_1.jpg",
    "https://sxd.kk.aliyun.com/img/swiper1_2.jpg",
    "https://sxd.kk.aliyun.com/img/swiper1_3.jpg",
    "https://sxd.kk.aliyun.com/img/swiper1_4.jpg",
    "https://sxd.kk.aliyun.com/img/swiper1_5.jpg",
    "https://sxd.kk.aliyun.com/img/swiper1_6.jpg"
  ]
  const width = window.screen.width;
  const height =  window.screen.height;
  if(width>height){
    images=[
      "https://sxd.kk.aliyun.com/img/swiper1.jpg",
      "https://sxd.kk.aliyun.com/img/swiper2.jpg",
      "https://sxd.kk.aliyun.com/img/swiper3.jpg",
      "https://sxd.kk.aliyun.com/img/swiper4.jpg",
      "https://sxd.kk.aliyun.com/img/swiper5.jpg",
      "https://sxd.kk.aliyun.com/img/swiper6.jpg"
    ]
  }
  export default {
    name: "swiperCom",
    data: function () {
      return {
        imgs: images,
        awiperDirection:'vertical'
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const state = reactive({
        imgs: images,
      })
      onMounted(()=>{
        window.addEventListener('resize', function() {
          const width = window.screen.width;
          const height =  window.screen.height;

          // Toast("5555");
          if(width>height){
            // Toast("宽111");
            state.awiperDirection='vertical'
            state.imgs=[
              "https://sxd.kk.aliyun.com/img/swiper1.jpg",
              "https://sxd.kk.aliyun.com/img/swiper2.jpg",
              "https://sxd.kk.aliyun.com/img/swiper3.jpg",
              "https://sxd.kk.aliyun.com/img/swiper4.jpg",
              "https://sxd.kk.aliyun.com/img/swiper5.jpg",
              "https://sxd.kk.aliyun.com/img/swiper6.jpg"
            ]
          }else {
            // Toast("高22222");
            state.awiperDirection='horizontal ';
            state.imgs=[
              "https://sxd.kk.aliyun.com/img/swiper1_1.jpg",
              "https://sxd.kk.aliyun.com/img/swiper1_2.jpg",
              "https://sxd.kk.aliyun.com/img/swiper1_3.jpg",
              "https://sxd.kk.aliyun.com/img/swiper1_4.jpg",
              "https://sxd.kk.aliyun.com/img/swiper1_5.jpg",
              "https://sxd.kk.aliyun.com/img/swiper1_6.jpg"
            ]
          }
        })
      })
      return {
        ...toRefs(state),
        modules: [Navigation, Pagination, Scrollbar, A11y],
      };
    },
  };

</script>

<style lang='less' scoped>
  .swiper-container {
    width: 7.1rem;
    height: 3rem;
    border-radius: 0.1rem;
    --swiper-theme-color: #ff6600;
    --swiper-pagination-color: #248cc0;
    --swiper-navigation-color: #248cc0;
    --swiper-navigation-size: 20px;
  }
  .swiper-slide .swiperImg{
    width: 100%;
    height: 100%;
  }
  .swiper-slide .swiperImg1{
    background: url("https://sxd.kk.aliyun.com/img/swiper1_1.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  .swiper-slide .swiperImg2{
    background: url("https://sxd.kk.aliyun.com/img/swiper1_2.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  .swiper-slide .swiperImg3{
    background: url("https://sxd.kk.aliyun.com/img/swiper1_3.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  .swiper-slide .swiperImg4{
    background: url("https://sxd.kk.aliyun.com/img/swiper1_4.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  .swiper-slide .swiperImg5{
    background: url("https://sxd.kk.aliyun.com/img/swiper1_5.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  .swiper-slide .swiperImg6{
    background: url("https://sxd.kk.aliyun.com/img/swiper1_6.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  @media (orientation: portrait) {
  /*  指定输出设备中的页面可见区域高度大于或等于宽度*/
    .swiper-container {
      /deep/.swiper-pagination{
        left: 0.266rem;

      }
    }
  }
  @media screen and (orientation: landscape) {
    .swiper-container {
      /deep/.swiper-pagination{
        right:50%;
        top:calc(100% - 1.5rem);
        transform: rotate(270deg);

      }
    }
    .swiper-slide .swiperImg1{
      background: url("https://sxd.kk.aliyun.com/img/swiper1.jpg") no-repeat center;
      background-size: 100% 100%;
    }
    .swiper-slide .swiperImg2{
      background: url("https://sxd.kk.aliyun.com/img/swiper2.jpg") no-repeat center;
      background-size: 100% 100%;
    }
    .swiper-slide .swiperImg3{
      background: url("https://sxd.kk.aliyun.com/img/swiper3.jpg") no-repeat center;
      background-size: 100% 100%;
    }
    .swiper-slide .swiperImg4{
      background: url("https://sxd.kk.aliyun.com/img/swiper4.jpg") no-repeat center;
      background-size: 100% 100%;
    }
    .swiper-slide .swiperImg5{
      background: url("https://sxd.kk.aliyun.com/img/swiper5.jpg") no-repeat center;
      background-size: 100% 100%;
    }
    .swiper-slide .swiperImg6{
      background: url("https://sxd.kk.aliyun.com/img/swiper6.jpg") no-repeat center;
      background-size: 100% 100%;
    }
  }
</style>
